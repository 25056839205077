
import { Options, mixins } from "vue-class-component"
import Join from "@/components/Join/index.vue"
import Panelist from "@/mixins/Panelist"
import { openChat } from "@/utils"

@Options({
  components: {
    Join
  }
})
export default class Completed extends mixins(Panelist) {
  onOpenChat() {
    openChat()
  }
}
